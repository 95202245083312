<template>
    <div class="auditOpinionPop">
        <div class="option-con">
            <el-input
                type="textarea"
                placeholder="请输入意见"
                class="option"
                v-model="opinionText"
            ></el-input>
        </div>
        <div class="text-center">
            <el-button @click="closeFn">
                取消
            </el-button>
            <el-button type="primary" @click="onSubmit">
                审批通过
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            opinionText: '',
        };
    },
    watch: {},
    computed: {},
    methods: {
        onSubmit() {
            if (this.opinionText) {
                this.$axios.get('/interfaceApi/Procurement/catalogs/supply/approval/' + this.extr.rowData.id + '/'
            + 1 + '?opinion=' + this.opinionText)
                    .then(res => {
                        if (res) {
                            this.$message.success('审核成功');
                            window.hideToast();
                            window.updateTable();
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.warning('请输入审核意见');
            }

        },
        closeFn() {
            window.hideToast();
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus">
.auditOpinionPop
    header
        height .6rem
        background #4f67ad
        line-height .6rem
        color #fff
        text-align center
        font-size .18rem
    .types
        padding .5rem .2rem
    .text-center
        text-align center
        position absolute
        bottom .3rem
        width 100%
    .option-con
        padding .3rem .2rem .2rem .2rem
        textarea
            height 1.5rem
</style>